<template>
  <v-row class="customer-search-wrap pt-5 pb-5">
    <v-col cols="8" md="4" class="pt-0 pb-0" v-if="search">
      <v-text-field
        label="Search"
        v-model="searchText"
        clearable
        @click:clear="clearSearch"
        append-icon="mdi-magnify"
        @keyup.enter="getData"
      ></v-text-field>
    </v-col>

    <v-col cols="4" md="4" v-if="search">
      <v-btn outlined rounded @click="getData">Search</v-btn>
    </v-col>

    <v-col cols="12" class="customer-search-table">
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        @click:row="itemDetail"
        sort-by="id"
        :sort-desc="true"
        :disable-sort="disableSort"
        :hide-default-header="disableHeader"
        :hide-default-footer="disableFooter"
        :disable-pagination="disablePagination"
        :detailPage="detailPage"
        class="elevation-1 cursor-pointer"
        loading-text="Loading... Please wait"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
            v-if="canput === 'true' && visibility != 'hide'"
            color="warning"
            small
            class="mr-5"
            @click="editItem(item)"
            >fas fa-edit</v-icon
          >
          <v-icon
            v-if="candelete === 'true'"
            color="error"
            small
            class="mr-5"
            @click="deleteItem(item.id)"
            >fas fa-trash-alt</v-icon
          >
          <v-icon v-if="detailPage" color="green" small @click="rowDetail(item)"
            >fas fa-folder</v-icon
          >
          <v-icon
            v-if="twilio === true"
            color="green"
            small
            @click="sendTwil(item)"
            >fas fa-send</v-icon
          >
        </template>
      </v-data-table>
    </v-col>

    <v-dialog v-model="dialogDelete" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">
          <span>Confirm Delete</span>
        </v-card-title>
        <v-card-text class="subtitle-1 pa-5">
          <span>Are you sure you want to delete this item?</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDelete">Cancel</v-btn>
          <v-btn text @click="deleteItemConfirm">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="6000" :color="snackColor">
      {{ error }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import CommonServices from "../../service/CommonServices";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import axios from "axios";
//import sendSMS from "../../twilio";

export default {
  props: [
    "headers",
    "componentURL",
    "params",
    "search",
    "searchValue",
    "staticData",
    "disableSort",
    "disableHeader",
    "item",
    "updateData",
    "exportRecord",
    "actionsURL",
    "disableFooter",
    "disablePagination",
    "detailPage",
    "twilio",
    "mobile_phone",
    "message",
    "visibility",
  ],
  data() {
    return {
      items: [],
      candelete: localStorage.getItem("candelete"),
      canput: localStorage.getItem("canput"),
      loading: false,
      deletedId: null,
      confirmDeleteItem: null,
      dialogDelete: false,
      searchText: this.searchValue,
      pageText: this.detailPage,
      snackbar: false,
      snackColor: "",
      clearable: true,
      error: false,
      url: this.componentURL,

      SmsUrl: "",
      postData: "",
      accountSid: "",
      authToken: "",
      sFromNumber: "",

      smsdata: {
        To: "+2082738141",
        From: "+12015970098",
        Body: "Test",
      },

      activity_record: {
        id: "",
        customer_id: "",
        cust_activity_type_id: "",
        campaign_element_id: "",
        campaign_detail_id: "",
        completed_date: this.$moment(String(Date())).format("MM-DD-YYYY"),
      },

      sms: {
        to: "",
        message: "",
      },
    };
  },
  created() {
    if (this.staticData && this.staticData.length) this.items = this.staticData;
    else this.getData();
  },
  watch: {
    staticData: {
      handler(arg) {
        this.items = arg;
      },
      deep: 1,
    },

    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    itemDetail(item) {
      this.$emit("row-clicked", item);
    },

    sendTwil(item) {
      var phoneNumber = parsePhoneNumberFromString(this.mobile_phone, "US");
      var smsbody = item.sms_text;
      console.log("Mobile Phone: ", phoneNumber);
      console.log("SMS Text: ", smsbody);
      console.log("ActivityID: ", item.id);
      console.log("CustomerID: ", item.customer_id);
      this.activity_record.id = item.id;
      this.activity_record.customer_id = item.customer_id;
      this.activity_record.cust_activity_type_id = item.cust_activity_type_id;
      this.activity_record.campaign_element_id = item.campaign_element_id;
      this.activity_record.campaign_detail_id = item.campaign_detail_id;
      console.log("Activity Record: ", this.activity_record);
      if (phoneNumber.isValid()) {
        this.phoneNumber = phoneNumber.number;
        console.log("Formatted Phone: ", phoneNumber);
        CommonServices.sendTwilio(
          this.phoneNumber,
          smsbody,
          this.activity_record
        );
        this.snackbar = true;
        this.snackColor = "success";
        this.error = "Twilio SMS Sent!";
      } else {
        this.snackbar = true;
        this.snackColor = "error";
        this.error = "Invalid Phone Number!";
        console.log("Phone Number is Invalid: ", phoneNumber.number);
      }
    },

    async sendMessage(item) {
      var phoneNumber = parsePhoneNumberFromString(this.mobile_phone, "US");
      var smsbody = item.sms_text;
      console.log("Mobile Phone: ", phoneNumber);
      console.log("SMS Text: ", smsbody);
      this.sms.to = phoneNumber;
      this.sms.message = smsbody;
      try {
        let response = await axios.post(
          "http://127.0.0.1:8000/db/communication/send/sms/",
          this.sms
        );
        console.log(response);
      } catch (err) {
        console.log(err);
      }
    },

    sendTwilio() {
      // const accountSid = process.env.TWILIO_ACCOUNT_SID;
      // const authToken = process.env.TWILIO_AUTH_TOKEN;
      // const sFromNumber = process.env.TWILIO_NUMBER;
      this.accountSid = "AC42e7e95da3572c4048ccb7035cfa9c48";
      this.authToken = "c51aa69a9f3204327d39ad2312fff108";
      this.sFromNumber = "+12015970098";

      const sBaseURL = "https://api.twilio.com";
      const phoneNumber = parsePhoneNumberFromString(this.smsdata.To, "TH");

      const headers = {
        "Content-Type": "application/json",
      };
      const auth = {
        username: this.accountSid,
        password: this.authToken,
      };

      //const sBodyText='Test'

      this.SmsUrl =
        sBaseURL + "/2010-04-01/Accounts/" + this.accountSid + "/SMS/Messages";

      console.log("URL: ", this.SmsUrl);
      console.log("To Phone: ", phoneNumber);

      if (!phoneNumber.isValid()) {
        //this.smsdata.to = phoneNumber.number;

        // this.postData = 'From=' + this.sFromNumber
        // + '&To=' + this.sms.to_number
        // + '&Body=' + sBodyText

        // this.postData='From=+12015970098&To=2082738141&Body=Test'
        console.log("PostData: ", this.smsdata);

        axios
          .post(this.SmsUrl, this.smsdata, {
            auth: auth,
            headers: headers,
          })
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        console.log("Invalid Phone Number!");
      }
    },

    rowDetail(value) {
      if (this.pageText) this.$router.push(`/${this.pageText}/${value.id}`);
    },

    getData() {
      this.loading = true;
      let params = this.params ? this.params : {};
      if (this.search !== "") params["search"] = this.searchText;
      CommonServices.listWithParams(this.componentURL, { params: params })
        .then((response) => {
          this.dataLoaded(true);
          if (response.results) {
            this.dataLoaded(true);
          } else {
            this.dataLoaded(false);
          }
          this.items = response.results;
          this.exportdata();
        })
        .catch((error) => {
          this.dataLoaded(false);
          this.snackbar = true;
          this.snackColor = "error";
          this.error = "Something went wrong";
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    addItem() {
      let url = this.actionsURL ? this.actionsURL : this.componentURL;
      CommonServices.addRecord(url, this.item)
        .then((response) => {
          this.items.push(response);
          if (this.exportRecord) this.exportdata();
          this.snackbar = true;
          this.snackColor = "success";
          this.error = "Record successfully added";
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          try {
            Object.keys(error.data).forEach((key) => {
              this.error = error.data[key][0];
            });
          } catch (e) {
            this.error = "Something went wrong";
          }
        });
    },

    updateItem() {
      let url = this.actionsURL ? this.actionsURL : this.componentURL;
      CommonServices.updateData(url, this.item)
        .then((response) => {
          const index = this.items.findIndex((item) => item.id === response.id);
          this.$set(this.items, index, response);
          if (this.exportRecord) this.exportdata();
          this.snackbar = true;
          this.snackColor = "success";
          this.error = "Record successfully updated";
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          try {
            Object.keys(error.response.data).forEach((key) => {
              this.error = error.response.data[key][0];
            });
          } catch (e) {
            this.error = "Something went wrong";
          }
        });
    },

    deleteItem(id) {
      this.deletedId = id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let url = this.actionsURL ? this.actionsURL : this.componentURL;
      CommonServices.deleteRecord(url, this.deletedId)
        .then(() => {
          let index = this.items.findIndex(
            (item) => item.id === this.deletedId
          );
          this.items.splice(index, 1);
          if (this.exportRecord) this.exportdata();
          this.snackbar = true;
          this.snackColor = "success";
          this.error = "Record successfully deleted";
        })
        .catch(() => {
          this.snackbar = true;
          this.snackColor = "error";
          this.error = "Something went wrong";
        });
      this.closeDelete();
    },

    exportdata() {
      this.$emit("export-data", this.items);
    },

    dataLoaded(loaded) {
      this.$emit("load-data", loaded);
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    editItem(item) {
      this.$emit("editItem", item);
    },

    clearSearch() {
      this.searchText = "";
      this.getData();
    },
  },
};
</script>

<style>
.headline {
  background: #ff5252;
  color: #ffffff;
}

.customer-search-wrap .col {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.customer-search-table .v-data-table {
  border-radius: 0;
  box-shadow: none !important;
}

.customer-search-table .v-data-table table {
  border: none !important;
}

.customer-search-table .v-data-table table th {
  border: none !important;
  color: #2d2d2d !important;
  font-family: Poppins;
  font-weight: normal;
  white-space: nowrap;
  font-size: 14px !important;
  height: 50px !important;
  padding: 0px 30px !important;
}

.customer-search-table .v-data-table table td {
  border: none !important;
}

thead.v-data-table-header tr th:first-child {
  border-radius: 5px 0 0 5px;
}

thead.v-data-table-header tr th {
  background-color: #f3f3f3 !important;
}

thead.v-data-table-header tr th:last-child {
  border-radius: 0 5px 5px 0;
}

.customer-search-table .v-data-table table tbody tr:last-child td {
  border-bottom: 1px solid #c4c4c4 !important;
}

.customer-search-table .v-data-table table td {
  padding: 0 30px !important;
  height: 50px !important;
  border-top: 1px solid #c4c4c4 !important;
  white-space: nowrap;
}

.customer-search-table th .v-icon:before {
  content: "\f0d8";
  font-family: FontAwesome;
  color: #000;
  font-size: 16px;
  position: relative;
  top: -5px;
  left: 10px;
}

.customer-search-table th .v-icon {
  transform: none !important;
  opacity: 1 !important;
}

.customer-search-table th .v-icon:after {
  content: "\f0d7";
  font-family: FontAwesome;
  opacity: 1 !important;
  background: transparent !important;
  transform: none !important;
  color: #000;
  font-style: normal;
  position: relative;
  top: 3px;
  font-size: 16px;
  left: 1px;
}

.cursor-pointer {
  cursor: pointer;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 13px;
  padding-right: 15px !important;
  padding-left: 15px !important;
  height: 45px !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

@media only screen and (max-width: 599px) {
  .customer-search-table .v-data-footer {
    margin-top: 0;
  }

  .v-application .customer-search-wrap {
    padding-top: 0 !important;
  }

  .sc-title h1 {
    font-size: 22px;
  }

  .customer-search-table .v-data-footer {
    margin-top: 0;
  }
}

@media only screen and (max-width: 587px) {
  .customer-search-table .v-data-table table td:first-child {
    border-radius: 0;
    border-left: 0 !important;
  }

  .customer-search-table .v-data-table table td:last-child {
    border-radius: 0;
    border-right: 0 !important;
  }

  .customer-search-table .v-data-table__mobile-table-row {
    margin-bottom: 20px;
    display: block !important;
    border: 1px solid #c4c4c4;
  }
}
</style>
