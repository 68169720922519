<template>
  <v-container style="margin-bottom: 100px">
    <v-row class="sc-title mt-7">
      <v-col md="8" sm="6">
        <div>
          <h1>REP SETTINGS</h1>
        </div>
      </v-col>
      <v-col md="4" sm="6">
        <div class="text-right">
          <v-btn color="primary" @click="$router.push({ name: 'Settings' })"
            >Back</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <v-row class="sc-title mt-7">
      <v-col md="8" sm="6">
        <div>
          <p>Rep = Dave Zwickel</p>
        </div>
      </v-col>
    </v-row>
    <v-row class="sc-title mt-7">
      <v-col md="4" sm="6">
        <div>
          <h1>Block Schedule</h1>
        </div>
      </v-col>
      <v-col md="8" sm="6">
        <v-dialog v-model="dialog" max-width="500" persistent>
          <template v-slot:activator="{ on, attrs }">
            <div class="text-right">
              <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                @click="reset = false"
              >
                Add Block Schedule
              </v-btn>
            </div>
          </template>

          <v-card>
            <div class="">
              <div class="popup-wrap">
                <v-btn class="close-popup" text @click.native="dialog = false">
                  <v-img
                    src="../../assets/close-icon.png"
                    alt="close-icon"
                  ></v-img>
                </v-btn>

                <div class="popup-wrap-inner step-one">
                  <div class="popup-title">
                    <h6>Add Block Schedule</h6>
                  </div>
                  <div class="customer-form">
                    <v-form v-model="valid" ref="form" lazy-validation>
                      <v-container>
                        <v-row class="pr-10 pl-10">
                          <v-col cols="12" sm="12">
                            <v-row>
                              <v-col cols="12" md="12" class="pt-0 pb-0">
                                <date-time
                                  v-model="addDayOfWeek.blockDate"
                                  :date="true"
                                  :reset="reset"
                                >
                                </date-time>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col cols="12" md="12" class="pt-0 pb-0">
                                <date-time
                                  :time="true"
                                  v-model="addDayOfWeek.from"
                                  :reset="reset"
                                  :label="'From'"
                                >
                                </date-time>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col cols="12" md="12" class="pt-0 pb-0">
                                <date-time
                                  :time="true"
                                  v-model="addDayOfWeek.to"
                                  :reset="reset"
                                  :label="'To'"
                                >
                                </date-time>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col cols="12" md="12" class="pt-0 pb-0">
                                <v-text-field
                                  label="Reason"
                                  v-model="addDayOfWeek.reason"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col cols="12" md="12">
                                <div class="text-right">
                                  <v-btn
                                    color="primary"
                                    dark
                                    @click="saveFormData"
                                  >
                                    Add
                                  </v-btn>
                                </div>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <datatable
          :headers="day_of_week_table_data.headers"
          :staticData="day_of_week_table_data.data"
        >
        </datatable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Datatable from "../Datatable/Datatable";
import DateTime from "../../components/DateTime/DateTime";

export default {
  components: { DateTime, Datatable },
  data() {
    return {
      dialog: false,
      valid: false,
      reset: false,
      day_of_week_table_data: {
        headers: [
          { text: "Block Date", value: "blockDate" },
          { text: "From", value: "from" },
          { text: "To", value: "to" },
          { text: "Reason", value: "reason" },
        ],
        data: [
          {
            block_date: "12/24/2020",
            from: "ALL DAY",
            to: "",
            reason: "HOLIDAY",
          },
          {
            block_date: "12/25/2020",
            from: "ALL DAY",
            to: "",
            reason: "HOLIDAY",
          },
          {
            block_date: "Thursday",
            from: "11:00:00 AM",
            to: "05:00:00 PM",
            reason: "REP TRAINING",
          },
          {
            block_date: "10/25/2020",
            from: "ALL DAY",
            to: "",
            reason: "VACACTION",
          },
          {
            block_date: "10/31/2020",
            from: "03:00:00 PM",
            to: "05:00:00 PM",
            reason: "APPT",
          },
        ],
      },
      addDayOfWeek: {
        blockDate: "",
        from: "",
        to: "",
        reason: "",
      },
    };
  },
  methods: {
    saveFormData() {
      let valid = this.$refs.form.validate();
      if (valid) {
        this.day_of_week_table_data.data.push(this.addDayOfWeek);
        this.dialog = false;
        this.addDayOfWeek = { blockDate: "", from: "", to: "", reason: "" };
        this.reset = true;
      }
    },
  },
};
</script>

<style></style>
